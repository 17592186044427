var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.e2eeRegistered === true
        ? [
            _c("v-col", { attrs: { cols: 12, sm: 7 } }, [
              _vm._v(
                "\n        End-to-end encryption (E2EE) is enabled on your account.  Please share your E2EE passcode with your report recipients.\n      "
              )
            ]),
            _c(
              "v-col",
              {
                staticClass: "pl-2 pr-0 pt-0 pb-0",
                attrs: { cols: 12, sm: 5 }
              },
              [
                _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "800px",
                      fullscreen: _vm.$store.state.isMobile
                    },
                    on: { close: _vm.cleanupPasscodeDialog },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      color: "primary",
                                      outlined: "",
                                      block: "",
                                      width: "100%"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v("Change E2EE Passcode")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1198429246
                    ),
                    model: {
                      value: _vm.showE2EEPasscodeChangeDialog,
                      callback: function($$v) {
                        _vm.showE2EEPasscodeChangeDialog = $$v
                      },
                      expression: "showE2EEPasscodeChangeDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass: "d-inline-block",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Change End-to-End Encryption (E2EE) Recovery Passcode"
                              )
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.dialogLoading,
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeChangeDialog = false
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c("v-alert", { attrs: { type: "error" } }, [
                              _vm._v("\n                This will "),
                              _c("b", [_vm._v("NOT")]),
                              _vm._v(" change your "),
                              _c("b", [_vm._v("account")]),
                              _vm._v(" password.\n              ")
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n                You can change your E2EE Recovery passcode at anytime.\n                You and your report recipients will not need to enter the new passcode unless a new device/browser is used.\n                Once changed, the old passcode will no longer be valid.\n              "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "font-italic caption mb-2 mt-2" },
                              [
                                _vm._v("Changing the E2EE passcode does "),
                                _c("b", [_vm._v("NOT")]),
                                _vm._v(" generate a new certificate key pair.")
                              ]
                            ),
                            _c(
                              "form",
                              { attrs: { id: "changeE2eePasscodeForm" } },
                              [
                                _c("input", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    type: "text",
                                    name: "usr",
                                    readonly: true,
                                    autocomplete: "username"
                                  },
                                  domProps: {
                                    value: _vm.decryptionPasscodeUsername
                                  }
                                }),
                                _c("e2-e-e-passcode-input", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    "persistent-hint": true,
                                    type: "password",
                                    placeholder: "Enter your current passcode",
                                    autocomplete: "current-password",
                                    hint:
                                      "Current Passcode.  If you've forgotten your current passcode, then you must reset your passcode."
                                  },
                                  model: {
                                    value: _vm.originalE2eePasscode,
                                    callback: function($$v) {
                                      _vm.originalE2eePasscode = $$v
                                    },
                                    expression: "originalE2eePasscode"
                                  }
                                }),
                                _c("e2-e-e-passcode-input", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Enter your new passcode",
                                    autocomplete: "new-password",
                                    hint: "New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscode,
                                    callback: function($$v) {
                                      _vm.e2eePasscode = $$v
                                    },
                                    expression: "e2eePasscode"
                                  }
                                }),
                                _c("e2-e-e-passcode-input", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Confirm your new passcode",
                                    autocomplete: "new-password",
                                    hint: "Confirm New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscodeConfirm,
                                    callback: function($$v) {
                                      _vm.e2eePasscodeConfirm = $$v
                                    },
                                    expression: "e2eePasscodeConfirm"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("passcode-requirements"),
                            _vm.errorMessage
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { type: "error", dense: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.errorMessage) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: !_vm.$store.state.isMobile,
                                  color: "secondary",
                                  disabled: _vm.dialogLoading,
                                  icon: _vm.$store.state.isMobile
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeChangeDialog = false
                                  }
                                }
                              },
                              [
                                _c("v-icon", [_vm._v("close")]),
                                !_vm.$store.state.isMobile
                                  ? _c("span", [_vm._v("Close")])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  disabled: _vm.dialogLoading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeChangeDialog = !(_vm.showE2EEPasscodeResetDialog = true)
                                  }
                                }
                              },
                              [_vm._v("forgot passcode")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  disabled:
                                    !_vm.passCodeValid || _vm.dialogLoading,
                                  loading: _vm.dialogLoading
                                },
                                on: { click: _vm.changePasscode }
                              },
                              [_vm._v("Submit")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "800px",
                      fullscreen: _vm.$store.state.isMobile
                    },
                    on: { close: _vm.cleanupPasscodeDialog },
                    model: {
                      value: _vm.showE2EEPasscodeResetDialog,
                      callback: function($$v) {
                        _vm.showE2EEPasscodeResetDialog = $$v
                      },
                      expression: "showE2EEPasscodeResetDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _vm._v(
                            "\n              Rotate E2EE Passcode\n            "
                          )
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c("div", [
                              _vm._v(
                                "\n                You can rotate the E2EE passcode if you have forgotten your current passcode.\n              "
                              )
                            ]),
                            _c("div", { staticClass: "font-italic caption" }, [
                              _vm._v("Resetting the E2EE passcode "),
                              _c("b", [_vm._v("does")]),
                              _vm._v(" generate a new certificate key pair.")
                            ]),
                            _c("passcode-requirements"),
                            _c(
                              "form",
                              { attrs: { id: "resetDecryptionPasscodeForm" } },
                              [
                                _c("input", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    type: "text",
                                    name: "usr",
                                    readonly: true,
                                    autocomplete: "username"
                                  },
                                  domProps: {
                                    value: _vm.decryptionPasscodeUsername
                                  }
                                }),
                                _c("e2-e-e-passcode-input", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Enter your new passcode",
                                    autocomplete: "new-password",
                                    hint: "New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscode,
                                    callback: function($$v) {
                                      _vm.e2eePasscode = $$v
                                    },
                                    expression: "e2eePasscode"
                                  }
                                }),
                                _c("e2-e-e-passcode-input", {
                                  attrs: {
                                    disabled: _vm.dialogLoading,
                                    type: "password",
                                    "persistent-hint": true,
                                    placeholder: "Confirm your new passcode",
                                    autocomplete: "new-password",
                                    hint: "Confirm New Passcode"
                                  },
                                  model: {
                                    value: _vm.e2eePasscodeConfirm,
                                    callback: function($$v) {
                                      _vm.e2eePasscodeConfirm = $$v
                                    },
                                    expression: "e2eePasscodeConfirm"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-alert", { attrs: { type: "warning" } }, [
                              _vm._v(
                                "\n                Rotating the passcode will cause you and your report recipients to lose access to all existing screenshots, websites, & event data.  Only data encrypted after you change the passcode will be accessible.\n              "
                              )
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  block: "",
                                  disabled:
                                    !_vm.passCodeValid || _vm.dialogLoading,
                                  loading: _vm.dialogLoading
                                },
                                on: { click: _vm.rotatePasscode }
                              },
                              [
                                _vm._v(
                                  "\n                Rotate Passcode\n              "
                                )
                              ]
                            ),
                            _vm.errorMessage
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { type: "error", dense: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.errorMessage) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: "",
                                  disabled: _vm.dialogLoading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showE2EEPasscodeResetDialog = false
                                  }
                                }
                              },
                              [_vm._v("Close")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm.e2eeRegistered === false
        ? [
            _c(
              "v-col",
              {
                staticClass: "pt-1 pb-4 pl-2 pr-2",
                attrs: { cols: 12, sm: 5, "offset-sm": 7 }
              },
              [
                _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "800px",
                      fullscreen: _vm.$store.state.isMobile
                    },
                    on: { close: _vm.cleanupPasscodeDialog },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    color: "warning",
                                    outlined: "",
                                    block: "",
                                    width: "100%"
                                  }
                                },
                                on
                              ),
                              [_vm._v("Enable E2E Encryption")]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.showE2EEDialog,
                      callback: function($$v) {
                        _vm.showE2EEDialog = $$v
                      },
                      expression: "showE2EEDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _vm._v(
                            "\n              End-to-End Encryption (E2EE)\n            "
                          )
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _vm.currentState === 0
                              ? [
                                  _c("div", { staticClass: "mb-2" }, [
                                    _vm._v(
                                      "End-to-end encryption (E2EE) is a crucial security feature that safeguards your data. To enable E2EE, you'll need to set up an E2EE passcode, which you'll be prompted to enter from time to time. We recommend writing this passcode down in a secure place. If you want others to access your accountability report, you'll need to share the passcode with them."
                                    )
                                  ])
                                ]
                              : _vm.currentState === 1
                              ? [
                                  _c("div", { staticClass: "mb-2" }, [
                                    _vm._v(
                                      "Your E2EE passcode functions like a Wi-Fi password. "
                                    ),
                                    _c("span", {}, [
                                      _vm._v(
                                        "Just as someone needs your Wi-Fi password to connect to your Wi-Fi, anyone who wants to view your report must enter your E2EE passcode."
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "mb-2" }, [
                                    _vm._v("For more information, visit our "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "https://blog.truple.io/2020/07/08/end-to-end-encryption-accountability-app.html#end-to-end-encryption-frequently-asked-questions",
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("E2EE Announcement")]
                                    ),
                                    _vm._v(".")
                                  ])
                                ]
                              : _vm.currentState === 2
                              ? [
                                  _c("div", { staticClass: "headline" }, [
                                    _vm._v("E2EE Recovery Passcode")
                                  ]),
                                  _vm._v(
                                    "\n\n                We strongly recommend you:\n                "
                                  ),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "Write down your passcode on paper"
                                      )
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Let your browser save your passcode"
                                      )
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Share your e2ee passcode with report recipients"
                                      )
                                    ])
                                  ])
                                ]
                              : [
                                  _c(
                                    "v-expand-transition",
                                    [
                                      !_vm.showEnableE2EEForm
                                        ? _c(
                                            "v-card",
                                            { attrs: { outlined: "" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: { type: "warning" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Make sure you write your E2EE passcode down somewhere safe!\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: { type: "warning" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        You "
                                                      ),
                                                      _c("b", [_vm._v("must")]),
                                                      _vm._v(
                                                        " share your E2EE passcode with anyone you send your accountability reports to.\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showEnableE2EEForm = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        I understand\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "form",
                                            { attrs: { id: "enableE2eeForm" } },
                                            [
                                              _c("input", {
                                                staticStyle: {
                                                  display: "none"
                                                },
                                                attrs: {
                                                  type: "text",
                                                  name: "usr",
                                                  readonly: "true",
                                                  autocomplete: "username"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.decryptionPasscodeUsername
                                                }
                                              }),
                                              _c("e2-e-e-passcode-input", {
                                                attrs: {
                                                  autofocus: true,
                                                  type: "password",
                                                  placeholder:
                                                    "Recovery passcode",
                                                  "persistent-hint": true,
                                                  autocomplete: "new-password",
                                                  hint: "Passcode"
                                                },
                                                model: {
                                                  value: _vm.e2eePasscode,
                                                  callback: function($$v) {
                                                    _vm.e2eePasscode = $$v
                                                  },
                                                  expression: "e2eePasscode"
                                                }
                                              }),
                                              _c("e2-e-e-passcode-input", {
                                                attrs: {
                                                  type: "password",
                                                  placeholder:
                                                    "Confirm recovery passcode",
                                                  "persistent-hint": true,
                                                  autocomplete: "new-password",
                                                  hint: "Confirm Passcode"
                                                },
                                                model: {
                                                  value:
                                                    _vm.e2eePasscodeConfirm,
                                                  callback: function($$v) {
                                                    _vm.e2eePasscodeConfirm = $$v
                                                  },
                                                  expression:
                                                    "e2eePasscodeConfirm"
                                                }
                                              }),
                                              _c("passcode-requirements"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    color: "primary",
                                                    block: "",
                                                    disabled:
                                                      !_vm.passCodeValid ||
                                                      _vm.dialogLoading,
                                                    loading: _vm.dialogLoading
                                                  },
                                                  on: { click: _vm.enableE2EE }
                                                },
                                                [
                                                  _vm._v(
                                                    "Enable E2E Encryption"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ]
                          ],
                          2
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _vm.currentState === 0
                              ? [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        color: "secondary",
                                        disabled: _vm.dialogLoading
                                      },
                                      on: { click: _vm.closeE2EEDialog }
                                    },
                                    [_vm._v("Close")]
                                  )
                                ]
                              : [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        color: "secondary",
                                        disabled: _vm.dialogLoading
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.currentState--
                                        }
                                      }
                                    },
                                    [_vm._v("Back")]
                                  )
                                ],
                            _c("v-spacer"),
                            _vm.currentState <= 2
                              ? _c(
                                  "span",
                                  { staticClass: "text-secondary text-body-2" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.currentState + 1) + " / 4"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c("v-spacer"),
                            _vm.currentState <= 2
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.currentState++
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "v-col",
              {
                staticClass: "pa-2 text-center",
                attrs: { cols: 12, sm: 5, "offset-sm": 7 }
              },
              [
                _c("v-progress-circular", {
                  attrs: { size: 25, indeterminate: "", color: "primary" }
                })
              ],
              1
            )
          ],
      _vm.$store.state.account &&
      _vm.$store.state.account.account.debug &&
      _vm.e2eeRegistered === true
        ? _c(
            "v-col",
            { staticClass: "pa-2", attrs: { cols: 12, sm: 5, "offset-sm": 7 } },
            [
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.dialogLoading },
                  on: { click: _vm.unregisterUserFromE2ee }
                },
                [_vm._v("Unregister User")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showNextSteps,
            callback: function($$v) {
              _vm.showNextSteps = $$v
            },
            expression: "showNextSteps"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "\n          " + _vm._s(_vm.nextStepsTitle) + "\n        "
                )
              ]),
              _c("v-card-text", {
                domProps: { innerHTML: _vm._s(_vm.nextStepsHtml) }
              }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", outlined: "", color: "primary" },
                      on: { click: _vm.closeE2EEDialog }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.nextStepsHtmlButtonText || "Okay") +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }