<template>
  <v-card>
    <v-card-text>
      <plan-selection @on-cancel="onCancel" @on-continue="onContinue" submit-text="Submit" :subscription-change="true" />
    </v-card-text>
  </v-card>
</template>

<script>
import ChargeApi from "@/aws/charge/ChargeApi"
import PlanSelection from "@/components/accounts/steps/PlanSelection";
import Utils from "@/Utils";
import StoreConstants from "@/StoreConstants";
import AndroidInterface from "@/services/AndroidInterface";
import AWSClockSkewHandler from "@/aws/AWSClockSkewHandler";

export default {
  name: "ChangeSubscription",
  components: {
    PlanSelection
  },
  methods: {
    onCancel() {
      console.log('onCancel')
      this.cancel()
    },
    async onContinue(selectedPlan) {
      console.log('onContinue ' + JSON.stringify(selectedPlan))


      if(AndroidInterface.canUseGooglePlayForPurchases() && AndroidInterface.installedFromGooglePlay()) {

        if(this.$store.state.account.account.accountSettings.subscriptionIdentifiers.subscriptionId === selectedPlan.googlePlaySubscriptionId) {
          //open google play app so they can resubscribe from there.  This sets auto-renewal to true for current subscription that has auto-renewal disabled.
          AndroidInterface.openWebsiteInBrowser('https://play.google.com/store/account/subscriptions')
        } else {
          //change subscription from one plan to another
          AndroidInterface.changeThroughGooglePlay(selectedPlan.googlePlaySubscriptionId, this.$store.state.account.account.accountSettings.subscriptionIdentifiers.purchaseToken)
        }
      } else {
        //stripe

        let priceId = selectedPlan.priceId[process.env.VUE_APP_ENV]

        try {
          let lastModified = AWSClockSkewHandler.getTime()
          await ChargeApi.changeStripeSubscription(priceId)
          await Utils.sleep(1000)
          for(let c = 0; c < 10; c++) {
            await this.$store.dispatch(StoreConstants.actions.loadAccount)
            if(this.$store.state.account.account.accountSettings.lastModified > lastModified) {
              break;
            } else {
              await Utils.sleep(1000)
            }
          }
          await Utils.sleep(1000)
          await this.$store.dispatch(StoreConstants.actions.loadAccount)
          this.done()
        } catch(e) {
          console.log(e)
          alert('There was an error... please try again.  If this repeats, contact support@truple.io')
        }
      }

      this.done()
    },
    done() {
      this.$emit('done')
    },
    cancel() {
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped>

</style>
