import accountLockClientFactory from "./apigClient";
import store from '../../store'
import AWSClockSkewHandler from "../AWSClockSkewHandler";

class AccountLockApi {

  constructor() {
    this.inited = false
  }

  init() {
    if(!this.inited) {
      this.inited = true
      console.log(process.env.VUE_APP_ACCOUNT_LOCK_URL)
      this.client = accountLockClientFactory.newClient(store.state.credentials, process.env.VUE_APP_ACCOUNT_LOCK_URL)
    }
  }

  async lock(email, blockReports) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.lockPost({
        deviceUUID: store.state.deviceUUID
      }, {
        value: email,
        blockReports: blockReports
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async unlock(pin) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.unlockPost({
        deviceUUID: store.state.deviceUUID
      }, {
        value: pin
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async requestUnlock() {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.unlockRequestPost({
        deviceUUID: store.state.deviceUUID
      }, {})
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

}

export default (new AccountLockApi())