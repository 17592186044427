var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.cancel } },
            [_c("v-icon", [_vm._v("\n        close\n      ")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          !_vm.$store.getters.isCurrentSubscriptionPlanAvailable
            ? _c("v-alert", { attrs: { type: "warning" } }, [
                _vm._v(
                  "\n      IMPORTANT!!! - Your current plan is grandfathered in.  If you cancel, you won't be able to resubscribe at the same price.\n    "
                )
              ])
            : _vm._e(),
          _c(
            "flow-form",
            {
              staticStyle: { "padding-top": "10px", "padding-bottom": "10px" },
              attrs: {
                standalone: false,
                progressbar: false,
                navigation: false,
                questions: _vm.questions,
                language: _vm.languageModel
              },
              on: { complete: _vm.onComplete, submit: _vm.onComplete }
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-h4",
                  attrs: { slot: "complete" },
                  slot: "complete"
                },
                [
                  _vm._v("\n        We're cancelling your subscription."),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n        Please don't navigate away or click the back button.  You'll be redirected shortly.\n      "
                  )
                ]
              ),
              _c("div", {
                attrs: { slot: "completeButton" },
                slot: "completeButton"
              })
            ]
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", disabled: _vm.cancelling },
              on: { click: _vm.cancel }
            },
            [_vm._v("\n      Cancel\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }