var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-1 mb-1" }, [
      _c("ul", [
        _c("li", [
          _vm._v("Passcodes must be greater than 6 characters in length")
        ]),
        _c("li", [
          _vm._v("Do "),
          _c("b", [_vm._v("NOT")]),
          _vm._v(" use the same password as your account password")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }