import monthlyPlans from './plans.2019.05.21'
import annualPlans from './annual.plans.2019.05.21'
// import store from "@/store";
//
// /**
//  * limited plans are limited to people who load the Account page through the Android app
//  * and they have never subscribed.  On 7/8/21 we removed the 1 device plan.
//  * @returns {*}
//  */
// function shouldOfferLimitedPlans() {
//   return false
// }

class DynamicPlans {

  getAnnualPlans() {
    // await store.state.accountLoadedPromise
    // if(shouldOfferLimitedPlans(store)) {
    //   return annualPlans
    // } else {
      return annualPlans.filter(x => { return x.value.available })
    // }
  }

  getMonthlyPlans() {
    // await store.state.accountLoadedPromise
    // if(shouldOfferLimitedPlans(store)) {
    //   return monthlyPlans
    // } else {
      return monthlyPlans.filter(x => { return x.value.available })
    // }
  }
}

export default (new DynamicPlans())