<template>
  <div class="mt-1 mb-1">
    <ul>
      <li>Passcodes must be greater than 6 characters in length</li>
      <li>Do <b>NOT</b> use the same password as your account password</li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "PasscodeRequirements"
  }
</script>

<style scoped>

</style>