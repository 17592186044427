<template>
  <v-card>
    <v-card-text>
      <plan-selection @on-cancel="onCancel" @on-continue="onContinue" />
    </v-card-text>
  </v-card>
</template>

<script>

const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)

import SegmentTracking from "@/services/SegmentTracking";
import ChargeApi from "@/aws/charge/ChargeApi"
import PlanSelection from "@/components/accounts/steps/PlanSelection"
import AndroidInterface from "@/services/AndroidInterface";

export default {
  name: "Subscribe",
  components: {PlanSelection},
  methods: {
    onCancel() {
      console.log('onCancel')
      this.cancel()
    },
    async onContinue(selectedPlan) {
      console.log('onContinue ' + JSON.stringify(selectedPlan))

      SegmentTracking.track('AddPaymentInfo', {
        category: 'Checkout'
      })

      const installedFromGooglePlay = AndroidInterface.installedFromGooglePlay()
      console.log(`installedFromGooglePlay = ${installedFromGooglePlay}`)

      const applicationId = AndroidInterface.getApplicationId()
      console.log(`applicationId = ${applicationId}`)

      if(AndroidInterface.canUseGooglePlayForPurchases() &&
          (installedFromGooglePlay === true || installedFromGooglePlay === undefined || installedFromGooglePlay === null)
          &&
          (applicationId === 'com.camhart.netcountable' || applicationId === undefined || applicationId === null)) {

        AndroidInterface.purchaseThroughGooglePlay(
            selectedPlan.googlePlaySubscriptionId,
            selectedPlan.planCode,
            parseFloat(selectedPlan.price)
        )

        this.done()
      } else {
        //stripe

        let priceId = selectedPlan.priceId[process.env.VUE_APP_ENV]
        //call create stripe session
        let response = await ChargeApi.createSession(
            priceId,
            `${process.env.VUE_APP_HOST}/postpayment?waitForSubscription=true&newSubscription=true&subscriptionAmount=${encodeURIComponent(selectedPlan.price)}&subscriptionPlan=${encodeURIComponent(priceId)}`,
            `${process.env.VUE_APP_HOST}/account?subscribe=true`,
            this.$store.getters.shouldGetFreeTrialDueToReferrer
        )
        let sessionResponse = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        })

        this.done()
      }
    },
    done() {
      this.$emit('done')
    },
    cancel() {
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped>

</style>