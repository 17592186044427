var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "ma-0", attrs: { row: "", wrap: "" } },
    [
      _c("v-col", { staticClass: "d-flex", attrs: { cols: 12 } }, [
        _c("span", { staticClass: "text-h6 mx-auto" }, [
          _vm._v("\n        Pick a plan\n      ")
        ])
      ]),
      !_vm.loading
        ? _c(
            "v-col",
            {
              staticStyle: { cursor: "pointer" },
              attrs: {
                cols: 10,
                offset: 1,
                sm: 5,
                "offset-sm": 1,
                md: 4,
                "offset-md": 2,
                elevation:
                  _vm.selectedPlan === _vm.availableAnnualPlans[0].value
                    ? 24
                    : 6
              },
              on: {
                click: function($event) {
                  return _vm.planPicked(_vm.availableAnnualPlans[0])
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-center subtitle-1 white--text plan-type pt-1",
                  class:
                    _vm.selectedPlan === _vm.availableAnnualPlans[0].value
                      ? _vm.continueLoading
                        ? "loading"
                        : "chosen"
                      : ""
                },
                [_vm._v("\n        Annual Plan\n      ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "accent-border pa-2 text-center text-body-1 font-weight-black d-flex",
                  class:
                    _vm.selectedPlan === _vm.availableAnnualPlans[0].value
                      ? _vm.continueLoading
                        ? "loading"
                        : "chosen"
                      : ""
                },
                [
                  _c("div", { staticClass: "mx-auto my-auto" }, [
                    _c("div", { staticClass: "text-h4 font-weight-medium" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.availableAnnualPlans[0].text))
                      ]),
                      _c("span", { staticClass: "caption" }, [
                        _vm._v(" / month")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "caption text-sm-body-2 font-italic",
                        staticStyle: { color: "gray" }
                      },
                      [_vm._v(_vm._s(_vm.availableAnnualPlans[0].subtext))]
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-col",
            {
              staticStyle: { cursor: "pointer" },
              attrs: {
                cols: 10,
                offset: 1,
                sm: 5,
                "offset-sm": 0,
                md: 4,
                elevation:
                  _vm.selectedPlan === _vm.availableMonthlyPlans[0].value
                    ? 24
                    : 6
              },
              on: {
                click: function($event) {
                  return _vm.planPicked(_vm.availableMonthlyPlans[0])
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-center subtitle-1 white--text plan-type pt-1",
                  class:
                    _vm.selectedPlan === _vm.availableMonthlyPlans[0].value
                      ? _vm.continueLoading
                        ? "loading"
                        : "chosen"
                      : ""
                },
                [_vm._v("\n        Monthly Plan\n      ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "accent-border pa-2 text-center text-body-1 font-weight-black d-flex",
                  class:
                    _vm.selectedPlan === _vm.availableMonthlyPlans[0].value
                      ? _vm.continueLoading
                        ? "loading"
                        : "chosen"
                      : ""
                },
                [
                  _c("div", { staticClass: "mx-auto my-auto" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-h4 font-weight-medium my-auto mx-auto"
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.availableMonthlyPlans[0].text))
                        ]),
                        _c("span", { staticClass: "caption" }, [
                          _vm._v(" / month")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "caption text-sm-body-2 font-italic",
                        staticStyle: { color: "gray" }
                      },
                      [_vm._v(_vm._s(_vm.availableMonthlyPlans[0].subtext))]
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-fade-transition",
        [
          _vm.selectedPlan && _vm.selectedPlan.frequency === "annual"
            ? _c(
                "v-alert",
                {
                  staticClass: "mx-auto mb-0",
                  attrs: { type: "info", color: "accent", outlined: "" }
                },
                [
                  _c("div", [
                    _vm._v("\n          You have chosen an "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "font-weight-bold text-decoration-underline"
                      },
                      [_vm._v("annual plan")]
                    ),
                    _vm._v(
                      ".  You will be billed for the entire year at once.\n        "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      !_vm.subscriptionChange
        ? [
            _c(
              "v-col",
              {
                staticClass: "text-center font-italic pa-2",
                attrs: { cols: 12 }
              },
              [
                _vm._v('\n        "This is the '),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("most genius solution")
                ]),
                _vm._v(
                  " to online accountability I've ever seen.\" - Abigale W.\n      "
                )
              ]
            ),
            _c(
              "v-col",
              {
                staticClass: "text-center font-italic pa-2",
                attrs: { cols: 12 }
              },
              [
                _vm._v('\n        "This is the '),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("best app for family accountability")
                ]),
                _vm._v('!" - Miranda S.\n      ')
              ]
            )
          ]
        : _c("v-col", { staticClass: "pa-2", attrs: { cols: 12 } }, [
            _vm._v(
              "\n      Subscription changes are pro-rated.  If you switch from an annual plan to a monthly plan, your account will be credited according to the remainder of the balance on the annual plan.  If you switch from a monthly plan to the annual plan, you'll pay a prorated amount.\n    "
            )
          ]),
      !_vm.$store.getters.isSubscribed
        ? _c(
            "v-col",
            { attrs: { cols: 12 } },
            [
              _vm.$store.getters.shouldGetFreeTrialDueToReferrer
                ? _c(
                    "p",
                    {
                      staticClass: "ma-3",
                      staticStyle: { "text-align": "center" }
                    },
                    [_vm._v("14 day free trial")]
                  )
                : _vm._e(),
              !_vm.$store.state.inApp
                ? _c(
                    "p",
                    {
                      staticClass: "ma-3",
                      staticStyle: { "text-align": "center" }
                    },
                    [_c("i", [_vm._v("30 day moneyback guarantee")])]
                  )
                : _vm._e(),
              !_vm.$store.state.inApp
                ? _c(
                    "p",
                    {
                      staticClass: "ma-3",
                      staticStyle: { "text-align": "center" }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pr-1",
                          attrs: { "x-small": "", color: "warning" }
                        },
                        [_vm._v("warning")]
                      ),
                      _c(
                        "a",
                        {
                          staticStyle: {
                            "font-size": "small",
                            "font-style": "italic"
                          },
                          attrs: {
                            href:
                              "https://" +
                              _vm.supportDomain +
                              "/articles/ios/ios-limitations",
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n          iPhone features are limited!\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "pl-1",
                          attrs: { "x-small": "", color: "warning" }
                        },
                        [_vm._v("warning")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "400" },
                  model: {
                    value: _vm.showFreeTrialPopup,
                    callback: function($$v) {
                      _vm.showFreeTrialPopup = $$v
                    },
                    expression: "showFreeTrialPopup"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Congratulations!")]),
                      _c("v-card-text", [
                        _vm._v(
                          "\n            Subscribe now to get a 14 day free trial.\n          "
                        )
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showFreeTrialPopup = false
                                }
                              }
                            },
                            [_vm._v("\n              Okay\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { staticClass: "mt-5", attrs: { cols: 12 } },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "secondary" },
              on: {
                click: function($event) {
                  return _vm.cancelClicked()
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              staticStyle: { float: "right" },
              attrs: {
                id: "continue-button",
                color: "primary",
                disabled: !_vm.selectedPlan,
                loading: _vm.continueLoading
              },
              on: {
                click: function($event) {
                  return _vm.continueClicked()
                }
              }
            },
            [_vm._v(_vm._s(_vm.submitText))]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }