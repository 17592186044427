var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "ma-2" },
    [
      _vm.$store.getters.isSubscribed
        ? _c(
            "v-col",
            { attrs: { cols: 12, sm: 7 } },
            [
              _vm.$store.getters.isAccountPastDue
                ? _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(
                      "\n      Your account is past due because of issues with your payment information. Please update your card info.\n    "
                    )
                  ])
                : _vm._e(),
              _c("v-text-field", {
                staticClass: "mb-2",
                attrs: {
                  readonly: true,
                  label:
                    "Subscription Plan" +
                    (_vm.$store.state.account.account.accountSettings.status
                      ? " (" +
                        _vm.$store.state.account.account.accountSettings
                          .status +
                        ")"
                      : "")
                },
                model: {
                  value: _vm.$store.getters.getSubscriptionPlan,
                  callback: function($$v) {
                    _vm.$set(_vm.$store.getters, "getSubscriptionPlan", $$v)
                  },
                  expression: "$store.getters.getSubscriptionPlan"
                }
              }),
              _vm.$store.getters.isSubscriptionAutoRenewalDisabled
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        "Your subscription has auto-renewal disabled and will be canceled on " +
                          new Date(
                            _vm.$store.state.account.account.accountSettings.nextBill
                          ).toLocaleDateString() +
                          "."
                      )
                    )
                  ])
                : [
                    _vm.$store.state.account.account.accountSettings.nextBill
                      ? _c("div", { staticClass: "caption" }, [
                          _vm._v(
                            _vm._s(
                              "Next bill: " +
                                new Date(
                                  _vm.$store.state.account.account.accountSettings.nextBill
                                ).toLocaleDateString()
                            )
                          )
                        ])
                      : _vm._e()
                  ],
              _vm.$store.state.account.account.accountSettings
                .subscriptionProcessor
                ? _c("div", { staticClass: "caption" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          "Payment processor: " +
                            _vm.$store.state.account.account.accountSettings
                              .subscriptionProcessor
                        ) +
                        "\n    "
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "v-col",
        {
          staticClass: "pa-2",
          attrs: {
            cols: 12,
            sm: 5,
            "offset-sm": _vm.$store.getters.isSubscribed ? 0 : 7
          }
        },
        [
          !_vm.$store.getters.isSubscribed
            ? _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    color: "primary",
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    width: "100%"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showSubscribeDialog()
                    }
                  }
                },
                [_vm._v("Subscribe")]
              )
            : [
                _c(
                  "v-btn",
                  {
                    class: _vm.showUseAndroidAppMessage ? "mb-1" : "mb-4",
                    attrs: {
                      outlined: "",
                      block: "",
                      disabled: _vm.showUseAndroidAppMessage,
                      color: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showChangeSubscriptionDialog()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$store.getters.isSubscriptionAutoRenewalDisabled
                            ? "Restart Subscription"
                            : "Change Subscription"
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _vm.$store.getters.getSubscriptionAllowedMonitoredDeviceCount >=
                15
                  ? _c("span", { staticClass: "caption" }, [
                      _vm._v("\n          Your account has a "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$store.getters
                              .getSubscriptionAllowedMonitoredDeviceCount
                          )
                        )
                      ]),
                      _vm._v(" device limit. To increase it, follow "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://" +
                              _vm.supportDomain +
                              "/articles/account/increase-device-limit"
                          }
                        },
                        [_vm._v("these instructions")]
                      ),
                      _vm._v(".\n        ")
                    ])
                  : _vm._e(),
                _vm.showUseAndroidAppMessage
                  ? _c("div", { staticClass: "mb-4 caption" }, [
                      _vm._v(
                        "\n          To change your subscription, please use the android app.\n        "
                      )
                    ])
                  : _vm._e(),
                _c(
                  "v-badge",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      value: _vm.isAccountPastDue,
                      overlap: "",
                      left: "",
                      "offset-x": "125px",
                      "offset-y": "10px",
                      color: "error"
                    }
                  },
                  [
                    _c("span", { attrs: { slot: "badge" }, slot: "badge" }, [
                      _vm._v("\n            update card info!\n          ")
                    ]),
                    !_vm.$store.getters.isSubscriptionAutoRenewalDisabled
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              href:
                                "https://play.google.com/store/paymentmethods",
                              target: "_blank",
                              outlined: "",
                              block: "",
                              color: "primary"
                            }
                          },
                          [_vm._v("\n            Change Card\n          ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm.$store.getters.isSubscribed
        ? _c(
            "v-col",
            { staticClass: "pl-2", attrs: { cols: 12, sm: 5, "offset-sm": 7 } },
            [
              !_vm.$store.getters.isCurrentSubscriptionPlanAvailable
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { text: "", dense: "", type: "warning" }
                    },
                    [
                      _vm._v(
                        "\n        Your current plan is grandfathered in.  If you cancel you won't be able to resubscribe at the same price.\n      "
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.$store.getters.isSubscriptionAutoRenewalDisabled
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        block: "",
                        color: "secondary",
                        disabled: _vm.$store.getters.isAccountLocked
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openCancelDialog.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Cancel Subscription")]
                  )
                : _vm._e(),
              _vm.$store.getters.isAccountLocked
                ? _c("p", [
                    _vm._v(
                      "Your account is locked.  To cancel your subscription, you must first unlock your account."
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }