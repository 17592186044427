<template>
  <v-row row wrap class="ma-0">
    <v-col :cols="12" class="d-flex">
      <span class="text-h6 mx-auto">
        Pick a plan
      </span>
    </v-col>

    <v-col :cols="10" :offset="1" :sm="5" :offset-sm="1" :md="4" :offset-md="2" v-if="!loading" @click="planPicked(availableAnnualPlans[0])" :elevation="selectedPlan === availableAnnualPlans[0].value ? 24 : 6" style="cursor: pointer;">
      <div class="text-center subtitle-1 white--text plan-type pt-1" :class="selectedPlan === availableAnnualPlans[0].value ? (continueLoading ? 'loading' : 'chosen') : ''">
        Annual Plan
      </div>
      <div class="accent-border pa-2 text-center text-body-1 font-weight-black d-flex" :class="selectedPlan === availableAnnualPlans[0].value ? (continueLoading ? 'loading' : 'chosen') : ''">
        <div class="mx-auto my-auto">
          <div class="text-h4 font-weight-medium"><span>{{availableAnnualPlans[0].text}}</span><span class="caption">&nbsp;/&nbsp;month</span></div>
          <div class="caption text-sm-body-2 font-italic" style="color: gray;">{{availableAnnualPlans[0].subtext}}</div>
        </div>
      </div>
    </v-col>

    <v-col :cols="10" :offset="1" :sm="5" :offset-sm="0" :md="4" v-if="!loading" @click="planPicked(availableMonthlyPlans[0])" :elevation="selectedPlan === availableMonthlyPlans[0].value ? 24 : 6"  style="cursor: pointer;">
      <div class="text-center subtitle-1 white--text plan-type pt-1" :class="selectedPlan === availableMonthlyPlans[0].value ? (continueLoading ? 'loading' : 'chosen') : ''">
        Monthly Plan
      </div>
      <div class="accent-border pa-2 text-center text-body-1 font-weight-black d-flex"
           :class="selectedPlan === availableMonthlyPlans[0].value ? (continueLoading ? 'loading' : 'chosen') : ''"
      >
        <div class="mx-auto my-auto">
          <div class="text-h4 font-weight-medium my-auto mx-auto"><span>{{availableMonthlyPlans[0].text}}</span><span class="caption">&nbsp;/&nbsp;month</span></div>
          <div class="caption text-sm-body-2 font-italic" style="color: gray;">{{availableMonthlyPlans[0].subtext}}</div>
        </div>
      </div>
    </v-col>

    <v-fade-transition>
      <v-alert type="info" color="accent" v-if="selectedPlan && selectedPlan.frequency === 'annual'" outlined class="mx-auto mb-0">
        <div>
          You have chosen an <span class="font-weight-bold text-decoration-underline">annual plan</span>.  You will be billed for the entire year at once.
        </div>
      </v-alert>
    </v-fade-transition>

    <template v-if="!subscriptionChange" >
      <v-col :cols="12" class="text-center font-italic pa-2">
        "This is the <span class="font-weight-bold">most genius solution</span> to online accountability I've ever seen."&nbsp;-&nbsp;Abigale&nbsp;W.
      </v-col>

      <v-col :cols="12" class="text-center font-italic pa-2">
        "This is the <span class="font-weight-bold">best app for family accountability</span>!"&nbsp;-&nbsp;Miranda&nbsp;S.
      </v-col>
    </template>
    <v-col :cols="12" class="pa-2" v-else>
      Subscription changes are pro-rated.  If you switch from an annual plan to a monthly plan, your account will be credited according to the remainder of the balance on the annual plan.  If you switch from a monthly plan to the annual plan, you'll pay a prorated amount.
    </v-col>

<!--&lt;!&ndash;    <v-flex xs12 class="mb-2 text-body-2">&ndash;&gt;-->
<!--&lt;!&ndash;      Plans are priced according to the number of <b>monitored</b> devices.  Choose a plan that will allow you to monitor all your devices.&ndash;&gt;-->
<!--&lt;!&ndash;    </v-flex>&ndash;&gt;-->

<!--    <v-flex xs12 sm8 offset-sm2 v-if="!loading">-->
<!--        <div class="text-center subtitle-1 white&#45;&#45;text plan-type pt-1">-->
<!--          Monthly Plan-->
<!--        </div>-->
<!--        <v-btn-toggle v-model="selectedPlan" style="display: block;" tile dense class="accent-border">-->
<!--          <v-btn v-for="monthlyPlan in availableMonthlyPlans" block :value="monthlyPlan.value" :color="selectedPlan && selectedPlan === monthlyPlan.value ? 'accent' : 'white'"-->
<!--            :class="{ 'white&#45;&#45;text' : selectedPlan && selectedPlan === monthlyPlan.value}" :key="monthlyPlan.text"-->
<!--            class="text-none">-->
<!--            {{monthlyPlan.text}}-->
<!--          </v-btn>-->
<!--        </v-btn-toggle>-->

<!--        <div cols="12" class="text-center subtitle-1 white&#45;&#45;text plan-type mt-2 pt-1">-->
<!--          Annual Plan (2 month discount)-->
<!--        </div>-->

<!--        <v-btn-toggle v-model="selectedPlan" style="display: block;" tile dense class="accent-border">-->
<!--          <v-btn v-for="annualPlan in availableAnnualPlans" block :value="annualPlan.value" :color="selectedPlan && selectedPlan === annualPlan.value ? 'accent' : 'white'"-->
<!--                 :class="{ 'white&#45;&#45;text' : selectedPlan && selectedPlan === annualPlan.value}"-->
<!--                :key="annualPlan.text"-->
<!--                 class="text-none"-->
<!--          >-->
<!--            {{annualPlan.text}}-->
<!--          </v-btn>-->
<!--        </v-btn-toggle>-->

<!--&lt;!&ndash;        <p class="caption mt-2 mb-1" style="text-align: center;">You only pay for <b>monitored</b> devices.</p>&ndash;&gt;-->

<!--&lt;!&ndash;        <p class="caption ma-1" ><span class="font-weight-bold">All plans</span> allows for 15 devices within a <u>single family</u>.  If you need coverage for more devices, email support@truple.io and we can increase your limit.</p>&ndash;&gt;-->
<!--    </v-flex>-->
    <v-col :cols="12" class="" v-if="!$store.getters.isSubscribed">
      <p style="text-align: center;" class="ma-3" v-if="$store.getters.shouldGetFreeTrialDueToReferrer">14 day free trial</p>
      <p style="text-align: center;" class="ma-3" v-if="!$store.state.inApp"><i>30 day moneyback guarantee</i></p>

      <p style="text-align: center;" class="ma-3" v-if="!$store.state.inApp">
        <v-icon x-small color="warning" class="pr-1">warning</v-icon>
        <a style="font-size: small; font-style: italic;" :href="`https://${supportDomain}/articles/ios/ios-limitations`" target="_blank">
          iPhone features are limited!
        </a>
        <v-icon x-small color="warning" class="pl-1">warning</v-icon>
      </p>

      <v-dialog v-model="showFreeTrialPopup" max-width="400">
        <v-card>
          <v-card-title>Congratulations!</v-card-title>
          <v-card-text>
            Subscribe now to get a 14 day free trial.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showFreeTrialPopup = false" color="primary">
              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-col>
<!--    <v-col :cols="12" class="mt-2" v-else>-->
<!--      <p class="mb-2 caption font-italic">Subscription upgrades will result in a <span class="error&#45;&#45;text">prorated</span> charge, so your first charge after upgrading may be different from your regular charge.  This allows you to upgrade mid-bill cycle without having to worry about being charged any extra.</p>-->
<!--    </v-col>-->
    <v-col :cols="12" class="mt-5">
      <v-btn text color="secondary" @click="cancelClicked()">Cancel</v-btn>
      <v-btn id="continue-button" class="white--text" color="primary" @click="continueClicked()" style="float: right;" :disabled="!selectedPlan" :loading="continueLoading" >{{submitText}}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import DynamicPlans from "@/components/accounts/DynamicPlans";
  import Utils from "@/Utils";

  export default {
    name: "PlanSelection",
    created() {

    },
    props: {
      submitText: {
        type: String,
        default: 'Continue'
      },
      subscriptionChange: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
        loading: false,
        continueLoading: false,
        showFreeTrialPopup: false,
        annual: true,
        selectedPlan: null,
        availableAnnualPlans: DynamicPlans.getAnnualPlans(),
        availableMonthlyPlans: DynamicPlans.getMonthlyPlans(),
      }
    },
    mounted() {
      this.showFreeTrialPopup = this.$store.getters.shouldGetFreeTrialDueToReferrer
    },
    methods: {
      planPicked(plan) {
        if(!this.continueLoading) {
          console.log('plan picked')
          this.selectedPlan = plan.value
        }
      },
      async continueClicked() {
        this.continueLoading = true
        console.log(`choose ${this.selectedPlan}`)
        this.$emit('on-continue', this.selectedPlan)
        await Utils.sleep(5000)
        this.continueLoading = false
      },
      cancelClicked() {
        this.$emit('on-cancel')
      }
    },
    computed: {
      planHint () {
        return 'Get 2 months free w/ annual plan'
      }
    }
  }
</script>

<style scoped lang="less">

  .plan-type {
    background-color: #A4A4A4;
    color: #A4A4A4;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;

    &.chosen {
      background-color: #00796B;
      color: #00796B;
    }

    &.loading {
      border: 2px solid #757575 !important;
    }
  }

  .accent-border {
    height: 90px;
    border: 2px solid #A4A4A4 !important;
    color: #A4A4A4;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;

    &.chosen {
      border: 2px solid #00796B !important;
      color: #00796B;
    }

    &.loading {
      border: 2px solid #757575 !important;
    }
  }
</style>