var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "max-width": "800px" } },
    [
      _vm.loading
        ? _c("loading")
        : _vm.showSubscribe
        ? _c("subscribe", {
            on: { cancel: _vm.cancelSubscribeDialog, done: _vm.subscribeDone }
          })
        : _vm.showChangeSubscription
        ? _c("change-subscription", {
            on: {
              cancel: _vm.cancelChangeSubscriptionDialog,
              done: _vm.changeSubscriptionDialogDone
            }
          })
        : _c(
            "v-card",
            { staticClass: "card basePadding" },
            [
              _c(
                "v-card-title",
                { staticClass: "primary title white--text" },
                [
                  _c("span", [_vm._v("Membership & Billing")]),
                  _c("v-spacer"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$store.state.account.accountId))
                  ])
                ],
                1
              ),
              (!_vm.$store.getters.isSubscribed &&
                !_vm.$store.state.inAppInstalledFromGoogle) ||
              _vm.stripeSubscription
                ? _c("stripe-subscription-management", {
                    attrs: { loading: _vm.loadingSubscribe },
                    on: {
                      "start-subscribe": _vm.showSubscribeDialog,
                      "change-subscription": _vm.changeSubscription,
                      "start-cancel": _vm.openCancelDialog
                    }
                  })
                : !_vm.$store.getters.isSubscribed || _vm.googleSubscription
                ? _c("google-subscription-management", {
                    attrs: { loading: _vm.loadingSubscribe },
                    on: {
                      "start-subscribe": _vm.showSubscribeDialog,
                      "change-subscription": _vm.changeSubscription,
                      resubscribe: _vm.resubscribeGooglePlay,
                      "start-cancel": _vm.openCancelDialog
                    }
                  })
                : _c("div", [
                    _vm._v(
                      "\n      If you can see this, contact support@truple.io and indicate that the subscription management component isn't working properly.\n    "
                    )
                  ]),
              _vm.$store.getters.isAccountLocked !== null
                ? [_c("v-divider"), _c("account-lock", { staticClass: "ma-2" })]
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-2",
                      attrs: { cols: 12, sm: 5, "offset-sm": 7 }
                    },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "600px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: "secondary",
                                          outlined: "",
                                          block: "",
                                          width: "100%"
                                        }
                                      },
                                      on
                                    ),
                                    [_vm._v("Reset Password")]
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.showResetPasswordDialog,
                            callback: function($$v) {
                              _vm.showResetPasswordDialog = $$v
                            },
                            expression: "showResetPasswordDialog"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n              Reset Account Password\n            "
                                )
                              ]),
                              _c("v-card-text", [
                                _vm._v(
                                  "\n              If you log in using your Google account, then Google controls your password and you must reset it with them.  See "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://support.google.com/mail/answer/41078",
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("this page")]
                                ),
                                _vm._v(" for instructions.\n              "),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  '\n              If you log in using an email/password, you can reset your password by logging out and clicking "'
                                ),
                                _c("b", [_vm._v("Forgot password?")]),
                                _vm._v('" on the login page.\n            ')
                              ]),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary",
                                      on: {
                                        click: function($event) {
                                          _vm.showResetPasswordDialog = false
                                        }
                                      }
                                    },
                                    [_vm._v("Close")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-2",
                      attrs: { cols: 12, sm: 5, "offset-sm": 7 }
                    },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "600px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: "secondary",
                                          outlined: "",
                                          block: "",
                                          width: "100%"
                                        }
                                      },
                                      on
                                    ),
                                    [_vm._v("Delete Account")]
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.showDeleteAccountDialog,
                            callback: function($$v) {
                              _vm.showDeleteAccountDialog = $$v
                            },
                            expression: "showDeleteAccountDialog"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n              Delete Account\n            "
                                )
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _vm.$store.state.account &&
                                  _vm.$store.state.account.account
                                    ? [
                                        _vm.$store.state.account.account
                                          .emailRecipientInvites &&
                                        _vm.$store.state.account.account
                                          .emailRecipientInvites.length !== 0
                                          ? _c(
                                              "v-alert",
                                              {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  type: "warning",
                                                  outlined: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  You must first reject any pending invites.\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.$store.state.account.account
                                          .emailRecipientFor &&
                                        _vm.$store.state.account.account
                                          .emailRecipientFor.length !== 0
                                          ? _c(
                                              "v-alert",
                                              {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  type: "warning",
                                                  outlined: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  You must first remove yourself as an email recipient before you can delete your account.\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.$store.state.account.account
                                          .emails &&
                                        _vm.$store.state.account.account.emails
                                          .length !== 0
                                          ? _c(
                                              "v-alert",
                                              {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  type: "warning",
                                                  outlined: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  You must first delete all report recipients from your account before you can delete your account.\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  _vm.$store.getters.getDevices.length !== 0
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-4",
                                          attrs: {
                                            type: "warning",
                                            outlined: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                You must first delete all devices from your account before you can delete your account.\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.$store.getters.isAccountLocked
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-4",
                                          attrs: {
                                            type: "warning",
                                            outlined: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                You must unlock your account before you can delete it.\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.$store.getters.isSubscribed &&
                                  !_vm.$store.getters
                                    .isSubscriptionAutoRenewalDisabled
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mt-4",
                                          attrs: {
                                            type: "warning",
                                            outlined: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                You must unsubscribe before you can delete your account.\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n\n              If you'd like to delete your account, you can do so below.  All accountability data will be deleted once it's 2 weeks old.  Truple may maintain data for security, fraud prevention, or regulatory compliance.  You'll be logged out upon successful account deletion.\n\n              "
                                  ),
                                  _c("v-checkbox", {
                                    attrs: {
                                      disabled:
                                        _vm.$store.getters.isAccountLocked ||
                                        (_vm.$store.getters.isSubscribed &&
                                          !_vm.$store.getters
                                            .isSubscriptionAutoRenewalDisabled) ||
                                        _vm.$store.getters.getDevices.length !==
                                          0 ||
                                        (_vm.$store.state.account.account
                                          .emailRecipientFor &&
                                          _vm.$store.state.account.account
                                            .emailRecipientFor.length !== 0) ||
                                        (_vm.$store.state.account.account
                                          .emails &&
                                          _vm.$store.state.account.account
                                            .emails.length !== 0) ||
                                        (_vm.$store.state.account.account
                                          .emailRecipientInvites &&
                                          _vm.$store.state.account.account
                                            .emailRecipientInvites.length !==
                                            0) ||
                                        _vm.deletingAccount,
                                      label:
                                        "I understand and would like to delete my account."
                                    },
                                    model: {
                                      value: _vm.deleteAccountCheckbox,
                                      callback: function($$v) {
                                        _vm.deleteAccountCheckbox = $$v
                                      },
                                      expression: "deleteAccountCheckbox"
                                    }
                                  })
                                ],
                                2
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary",
                                      attrs: { disabled: _vm.deletingAccount },
                                      on: {
                                        click: function($event) {
                                          _vm.showDeleteAccountDialog = false
                                        }
                                      }
                                    },
                                    [_vm._v("Close")]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary",
                                      attrs: {
                                        disabled:
                                          !_vm.deleteAccountCheckbox ||
                                          _vm.$store.getters.isAccountLocked ||
                                          (_vm.$store.getters.isSubscribed &&
                                            !_vm.$store.getters
                                              .isSubscriptionAutoRenewalDisabled) ||
                                          _vm.$store.getters.getDevices
                                            .length !== 0 ||
                                          (_vm.$store.state.account.account
                                            .emailRecipientFor &&
                                            _vm.$store.state.account.account
                                              .emailRecipientFor.length !==
                                              0) ||
                                          (_vm.$store.state.account.account
                                            .emails &&
                                            _vm.$store.state.account.account
                                              .emails.length !== 0) ||
                                          (_vm.$store.state.account.account
                                            .emailRecipientInvites &&
                                            _vm.$store.state.account.account
                                              .emailRecipientInvites.length !==
                                              0) ||
                                          _vm.deletingAccount,
                                        loading: _vm.deletingAccount
                                      },
                                      on: { click: _vm.deleteAccount }
                                    },
                                    [_vm._v("Delete Account")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$store.getters.isSubscribed
                ? [
                    _c("v-divider"),
                    _c("e2-e-encryption-management", { staticClass: "ma-2" })
                  ]
                : _vm._e()
            ],
            2
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showSubscriptionCancelledDialog,
            callback: function($$v) {
              _vm.showSubscriptionCancelledDialog = $$v
            },
            expression: "showSubscriptionCancelledDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n        Subscription canceled\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Your subscription's autorenewal has been cancelled.  You'll continue to have access to Truple for the remainder of your billing period.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                { staticClass: "centered" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary",
                      on: {
                        click: function($event) {
                          _vm.showSubscriptionCancelledDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Close\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showLeaveReviewDialog,
            callback: function($$v) {
              _vm.showLeaveReviewDialog = $$v
            },
            expression: "showLeaveReviewDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n        Would you mind leaving a review?\n      ")
              ]),
              _c("v-card-text", [
                _c("div", [
                  _vm._v(
                    "Your subscription has been cancelled. Real quick though, "
                  ),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("would you mind leaving a review for Truple?")
                  ]),
                  _vm._v("  We would really appreciate it.")
                ]),
                _c("br"),
                _c("a", { on: { click: _vm.leaveReview } }, [
                  _vm._v("Click here to leave a review.")
                ]),
                _c("div", { staticClass: "caption font-italic" }, [
                  _vm._v("If you're "),
                  _c("u", [_vm._v("short on time")]),
                  _vm._v(" just leave a star rating without a written review.")
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "centered" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary",
                      on: {
                        click: function($event) {
                          _vm.showLeaveReviewDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      on: {
                        click: function($event) {
                          return _vm.leaveReview()
                        }
                      }
                    },
                    [_vm._v("\n          Leave Review\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("email-recipient-invite-dialog", {
        on: { "on-accept": _vm.accountabilityPartnerAccepted }
      }),
      _c(
        "v-dialog",
        {
          staticClass: "d-flex",
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            "max-width": "960px"
          },
          model: {
            value: _vm.showCancelDialog,
            callback: function($$v) {
              _vm.showCancelDialog = $$v
            },
            expression: "showCancelDialog"
          }
        },
        [
          _vm.showCancelDialog
            ? _c("cancelation-survey", {
                staticClass: "mx-auto my-auto",
                on: {
                  "on-complete": _vm.cancelSubscription,
                  cancel: _vm.cancelCancelation
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "500px",
            fullscreen: _vm.$store.state.isMobile
          },
          model: {
            value: _vm.showDohWarningDialog,
            callback: function($$v) {
              _vm.showDohWarningDialog = $$v
            },
            expression: "showDohWarningDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { staticClass: "error--text pr-1" }, [
                    _vm._v("warning")
                  ]),
                  _vm._v("\n        iOS Warning\n      ")
                ],
                1
              ),
              _c("v-card-text", [
                _c("div", { staticClass: "headline pb-1" }, [
                  _vm._v("Please remove Truple from your iPhone/iPads.  "),
                  _c("span", { staticClass: "error--text" }, [
                    _vm._v(
                      "If you do not remove Truple before unsubscribing, you'll lose internet connection on all iOS devices!"
                    )
                  ])
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://blog.truple.io/2020/09/21/remove-doh-from-ios.html"
                    }
                  },
                  [
                    _vm._v(
                      "View instructions for removing Truple from iOS devices."
                    )
                  ]
                ),
                _c("div", { staticClass: "headline pt-5" }, [
                  _vm._v(
                    "Have you removed Truple from all of your iPhone/iPad devices?"
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.showDohWarningDialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.closeIOSCancelWarningDialog.apply(
                            null,
                            arguments
                          )
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }