var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters.isSubscribed
    ? _c(
        "v-row",
        { attrs: { wrap: "" } },
        [
          _c("v-col", { attrs: { cols: 12, sm: 7 } }, [
            _vm.$store.getters.isAccountLocked
              ? _c("div", [
                  _vm._v(
                    _vm._s(
                      'Your account is currently locked.  To unlock, click the "Unlock Account" button, which will send a unlock code to ' +
                        _vm.$store.getters.getAccountUnlocker +
                        ".  You must then ask them for the unlock code to unlock your account."
                    )
                  )
                ])
              : _c("div", [
                  _vm._v(
                    "\n        Your account is currently unlocked.\n        "
                  ),
                  _c("div", { staticClass: "caption" }, [
                    _vm._v(
                      "\n          You may change device settings, report recipients, delete screenshots, and cancel your subscription. To block your ability to make these changes and optionally block access to accountability reports, lock the account. Whether the account is locked or not, emails are always sent out to report recipients whenever significant changes are made.\n        "
                    )
                  ])
                ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: 12, sm: 5 } },
            [
              _vm.$store.getters.isAccountLocked
                ? _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", block: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.showUnlockDialog()
                        }
                      }
                    },
                    [_vm._v("Unlock Account")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", block: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.showLockDialog()
                        }
                      }
                    },
                    [_vm._v("\n        Lock Account\n      ")]
                  ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: _vm.$store.state.isMobile,
                    "max-width": "600px"
                  },
                  model: {
                    value: _vm.unlockDialog,
                    callback: function($$v) {
                      _vm.unlockDialog = $$v
                    },
                    expression: "unlockDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "headline",
                          attrs: { "primary-title": "" }
                        },
                        [_vm._v("\n            Unlock Account\n          ")]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                "padding-left": "1em",
                                "padding-right": "1em"
                              },
                              attrs: { wrap: "" }
                            },
                            [
                              _c("v-col", { attrs: { cols: 12 } }, [
                                _c(
                                  "p",
                                  { staticStyle: { "padding-right": "2em" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        "Click below to send " +
                                          _vm.$store.getters
                                            .getAccountUnlocker +
                                          "  your unlock pin."
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: 12 } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "centered",
                                      attrs: {
                                        disabled: _vm.alreadySent,
                                        color: "primary",
                                        block: "",
                                        loading: _vm.sendingUnlockPin
                                      },
                                      on: { click: _vm.sendUnlockPin }
                                    },
                                    [_vm._v("Send unlock pin")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "padding-right": "2em",
                                    "margin-top": "2em"
                                  },
                                  attrs: { cols: 8 }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Unlock Pin" },
                                    model: {
                                      value: _vm.unlockPin,
                                      callback: function($$v) {
                                        _vm.unlockPin = $$v
                                      },
                                      expression: "unlockPin"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticStyle: { "margin-top": "2em" },
                                  attrs: { cols: 4 }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "centered",
                                      attrs: {
                                        disabled: !_vm.unlockPin,
                                        loading: _vm.unlocking,
                                        color: "primary",
                                        block: ""
                                      },
                                      on: { click: _vm.unlock }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Unlock\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", outlined: "" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          ),
                          _c("v-spacer")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: _vm.$store.state.isMobile,
                    "max-width": "600px"
                  },
                  model: {
                    value: _vm.lockDialog,
                    callback: function($$v) {
                      _vm.lockDialog = $$v
                    },
                    expression: "lockDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "headline",
                          attrs: { "primary-title": "" }
                        },
                        [_vm._v("\n            Lock Account\n          ")]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("p", [
                            _vm._v(
                              "By locking your account, you will no longer be able to modify device settings, report recipients, delete screenshots from the website, or cancel your subscription."
                            )
                          ]),
                          _c("v-switch", {
                            attrs: {
                              label: "Block access to reports",
                              "persistent-hint": "",
                              hint:
                                "Enable to block the account owners web based access to screenshots and accountability reports."
                            },
                            model: {
                              value: _vm.blockReports,
                              callback: function($$v) {
                                _vm.blockReports = $$v
                              },
                              expression: "blockReports"
                            }
                          }),
                          _c("br"),
                          _c(
                            "p",
                            [
                              _vm._v("Please choose an individual from your "),
                              _c("router-link", { attrs: { to: "emails" } }, [
                                _vm._v("email list")
                              ]),
                              _vm._v(
                                " who will receive an unlock code when you need to unlock your account."
                              )
                            ],
                            1
                          ),
                          _c("v-select", {
                            attrs: {
                              items: _vm.emails,
                              label: "Choose individual",
                              "no-data-text":
                                "You have no report recipients setup",
                              "persistent-hint": "",
                              hint:
                                "Will receive an unlock code when you need to unlock.",
                              loading: _vm.loading
                            },
                            model: {
                              value: _vm.accountUnlocker,
                              callback: function($$v) {
                                _vm.accountUnlocker = $$v
                              },
                              expression: "accountUnlocker"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", outlined: "" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !_vm.accountUnlocker,
                                color: "primary",
                                loading: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.lock()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Lock Account\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }