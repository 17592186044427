<template>
  <v-row wrap v-if="$store.getters.isSubscribed">
    <v-col :cols="12" :sm="7">
      <div v-if="$store.getters.isAccountLocked">{{`Your account is currently locked.  To unlock, click the "Unlock Account" button, which will send a unlock code to ${$store.getters.getAccountUnlocker}.  You must then ask them for the unlock code to unlock your account.`}}</div>
      <div v-else>
        Your account is currently unlocked.
        <div class="caption">
          You may change device settings, report recipients, delete screenshots, and cancel your subscription. To block your ability to make these changes and optionally block access to accountability reports, lock the account. Whether the account is locked or not, emails are always sent out to report recipients whenever significant changes are made.
        </div>
      </div>
    </v-col>
    <v-col :cols="12" :sm="5">

      <v-btn v-if="$store.getters.isAccountLocked" @click="showUnlockDialog()"
             outlined
             block
             color="secondary"
             >Unlock Account</v-btn>

      <v-btn  v-else
              outlined
              block
              color="secondary"
              @click="showLockDialog()">
        Lock Account
      </v-btn>

      <v-dialog
        v-model="unlockDialog"
        :fullscreen="$store.state.isMobile"
        max-width="600px">
        <v-card>
          <v-card-title class="headline" primary-title>
            Unlock Account
          </v-card-title>
          <v-card-text>

            <v-row wrap style="padding-left: 1em; padding-right: 1em;">
              <v-col :cols="12">
                <p style="padding-right: 2em;">{{ `Click below to send ${$store.getters.getAccountUnlocker}  your unlock pin.` }}</p>
              </v-col>
              <v-col :cols="12">
                <v-btn class="centered"
                       :disabled="alreadySent"
                       color="primary"
                       block
                       @click="sendUnlockPin"
                       :loading="sendingUnlockPin">Send unlock pin</v-btn>
              </v-col>

              <v-col :cols="8" style="padding-right: 2em; margin-top: 2em;">
                <v-text-field
                        label="Unlock Pin"
                        v-model="unlockPin">

                </v-text-field>
              </v-col>
              <v-col :cols="4" style="margin-top: 2em;">
                <v-btn class="centered" :disabled="!unlockPin" :loading="unlocking" color="primary" block @click="unlock">
                  Unlock
                </v-btn>
              </v-col>
            </v-row>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="secondary" outlined
                   @click="cancel">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="lockDialog"
        :fullscreen="$store.state.isMobile"
        max-width="600px">
        <v-card>
          <v-card-title class="headline" primary-title>
            Lock Account
          </v-card-title>
          <v-card-text>
            <p>By locking your account, you will no longer be able to modify device settings, report recipients, delete screenshots from the website, or cancel your subscription.</p>

<!--            <p>Locking can optionally block web access to screenshots and other accountability data.  Report recipients will still have access to the screenshots and accountability data.</p>-->
            <v-switch
                v-model="blockReports"
                label="Block access to reports"
                persistent-hint
                hint="Enable to block the account owners web based access to screenshots and accountability reports."
            />

            <br />

            <p>Please choose an individual from your <router-link to="emails">email list</router-link> who will receive an unlock code when you need to unlock your account.</p>

            <v-select
              v-model="accountUnlocker"
              :items="emails"
              label="Choose individual"
              no-data-text="You have no report recipients setup"
              persistent-hint
              hint="Will receive an unlock code when you need to unlock."
              :loading="loading"
            >

            </v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="secondary" outlined
              @click="cancel">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!accountUnlocker"
                color="primary"
                @click="lock()"
                :loading="loading"
            >
              Lock Account
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </v-col>
  </v-row>
</template>

<script>
  import accountLockApi from '../../aws/accountlock/accountLockApi'
  import StoreConstants from '../../StoreConstants'

  export default {
    name: "AccountLock",
    data () {
      return {
        alreadySent: false,
        lockDialog: false,
        unlockDialog: false,
        accountUnlocker: null,
        blockReports: false,
        emails: null,
        loading: false,
        unlockPin: '',
        sendingUnlockPin: false,
        unlocking: false
      }
    },
    methods: {
      showUnlockDialog() {
        this.unlockDialog = true
      },
      showLockDialog() {
        this.lockDialog = true
      },
      cancel() {
        this.unlockDialog = false
        this.lockDialog = false
      },
      async lock () {
        this.loading = true
        console.log('locking!')
        try {
          let result = await accountLockApi.lock(this.accountUnlocker, this.blockReports)

          if(result.data.status && result.data.status === 'Failed') {
            //show alert
            await this.$swal({
              icon: 'warning',
              title: 'Failed to lock',
              text: 'Error: ' + result.data.message
            })
          } else if(result.data.status !== 'Success') {
            throw new Error('failed to lock account')
          }

          await this.$store.dispatch(StoreConstants.actions.loadAccount)


          this.lockDialog = false
        } catch(error) {
          console.log(error)
          this.$swal('there was an error locking your account.  Please try again.  If the issue persists, contact support@truple.io')
        } finally {
          this.loading = false
        }
      },
      async unlock() {
        this.unlocking = true
        console.log('unlocking!')
        try {
          let result = await accountLockApi.unlock(this.unlockPin)

          if(result.data.status !== 'Success') {
            throw new Error('failed to unlock account')
          }

          await this.$store.dispatch(StoreConstants.actions.loadAccount)

          this.unlockDialog = false

        } catch(error) {
          console.log(error)
          this.$swal('there was an error unlocking your account.  Please try again.  If the issue persists, contact support@truple.io')
        } finally {
          this.unlocking = false
        }

      },
      async sendUnlockPin() {
        this.sendingUnlockPin = true
        console.log('sending unlock pin!')
        try {
          let result = await accountLockApi.requestUnlock()

          if(result.data.status !== 'Success') {
            throw new Error('failed to send unlock pin')
          }

          this.alreadySent = true;

        } catch(error) {
          console.log(error)
          this.$swal('there was an error sending the unlock pin.  Please try again.  If the issue persists, contact support@truple.io.')
        } finally {
          this.sendingUnlockPin = false
        }
      },
      async refreshEmails() {
        this.loading = true
        let response = await this.api.getEmails()
        this.emails = response.data.map(emailObj => {
          return emailObj.email
        })

        if(this.emails.length === 1) {
          this.accountUnlocker = this.emails[0]
        }

        this.loading = false
      }

    },
    beforeMount() {
      this.refreshEmails()
    }
  }
</script>

<style scoped>

</style>
