<template>
  <v-row class="ma-2">
    <v-col :cols="12" :sm="7" v-if="$store.getters.isSubscribed">
      <v-alert type="error" v-if="$store.getters.isAccountPastDue">
        Your account is past due because of issues with your payment information. Please update your card info.
      </v-alert>
      <v-text-field
          class="mb-2"
          :readonly="true"
          v-model="$store.getters.getSubscriptionPlan"
          :label="`Subscription Plan${$store.state.account.account.accountSettings.status ? ` (${$store.state.account.account.accountSettings.status})` : '' }`"
      />
      <span v-if="$store.getters.isSubscriptionAutoRenewalDisabled">{{'Your subscription has auto-renewal disabled and will be canceled on ' + new Date($store.state.account.account.accountSettings.nextBill).toLocaleDateString() + '.' }}</span>
      <template v-else>
        <div v-if="$store.state.account.account.accountSettings.nextBill" class="caption">{{`Next bill: ${new Date($store.state.account.account.accountSettings.nextBill).toLocaleDateString()}`}}</div>
      </template>
      <div v-if="$store.state.account.account.accountSettings.subscriptionProcessor" class="caption">
        {{`Payment processor: ${$store.state.account.account.accountSettings.subscriptionProcessor}`}}
      </div>
    </v-col>

      <v-col :cols="12" :sm="5" class="pa-2" :offset-sm="$store.getters.isSubscribed ? 0 : 7">
        <v-btn v-if="!$store.getters.isSubscribed" @click="showSubscribeDialog()"
               block
               color="primary"
               :loading="loading"
               :disabled="loading"
               width="100%">Subscribe</v-btn>
        <template v-else>
          <v-btn
              @click="showChangeSubscriptionDialog()"
              outlined
              block
              :disabled="showUseAndroidAppMessage"
              :class=" showUseAndroidAppMessage ? 'mb-1' : 'mb-4'"
              color="primary">
            {{$store.getters.isSubscriptionAutoRenewalDisabled ? 'Restart Subscription' : 'Change Subscription'}}
          </v-btn>
          <span class="caption" v-if="$store.getters.getSubscriptionAllowedMonitoredDeviceCount >= 15">
            Your account has a <span>{{$store.getters.getSubscriptionAllowedMonitoredDeviceCount}}</span> device limit. To increase it, follow <a :href="`https://${supportDomain}/articles/account/increase-device-limit`">these instructions</a>.
          </span>
          <div class="mb-4 caption" v-if="showUseAndroidAppMessage">
            To change your subscription, please use the android app.
          </div>
          <v-badge :value="isAccountPastDue" style="width: 100%;" overlap left offset-x="125px" offset-y="10px" color="error">
            <span slot="badge">
              update card info!
            </span>
            <v-btn
                v-if="!$store.getters.isSubscriptionAutoRenewalDisabled"
                href="https://play.google.com/store/paymentmethods"
                target="_blank"
                outlined
                block
                class="mb-4"
                color="primary">
              Change Card
            </v-btn>
          </v-badge>
        </template>
      </v-col>

      <v-col :cols="12" :sm="5" :offset-sm="7" class="pl-2" v-if="$store.getters.isSubscribed">
        <v-alert text dense type="warning" class="mt-2" v-if="!$store.getters.isCurrentSubscriptionPlanAvailable">
          Your current plan is grandfathered in.  If you cancel you won't be able to resubscribe at the same price.
        </v-alert>
        <v-btn @click.stop="openCancelDialog" outlined block color="secondary" v-if="!$store.getters.isSubscriptionAutoRenewalDisabled" :disabled="$store.getters.isAccountLocked">Cancel Subscription</v-btn>
        <p v-if="$store.getters.isAccountLocked">Your account is locked.  To cancel your subscription, you must first unlock your account.</p>
      </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GoogleSubscriptionManagement",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if(this.$route.query.changeCard) {
      this.changeCard()
    }
  },
  methods: {
    changeCard() {
      window.open('https://play.google.com/store/paymentmethods', '_blank')
    },
    showSubscribeDialog() {
      this.$emit('start-subscribe')
    },
    showChangeSubscriptionDialog() {
      if(this.$store.getters.isSubscriptionAutoRenewalDisabled) {
        this.$emit('resubscribe')
      } else {
        this.$emit('change-subscription')
      }
    },
    openCancelDialog() {
      this.$emit('start-cancel')
    }
  },
  data() {
    return {
      supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN
    }
  },
  computed: {
    isAccountPastDue: {
      get: function() {
        return this.$store.getters.isAccountPastDue
      },
      set: function() {

      }
    },
    showUseAndroidAppMessage() {
      if(!this.$store.state.inApp) {
        // in web browser
        return true
      } else {
        // in app
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
