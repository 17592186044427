import chargeClientFactory from "./apigClient";
import store from '../../store'
import AWSClockSkewHandler from "../AWSClockSkewHandler";

class ChargeApi {

  constructor() {
    this.inited = false
  }

  init() {
    if(!this.inited) {
      console.log(process.env.VUE_APP_CHARGE_URL)
      this.client = chargeClientFactory.newClient(store.state.credentials, process.env.VUE_APP_CHARGE_URL)
      this.inited = true
    }
  }

  async createChangeCardSession(successUrl, cancelUrl) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.stripeSessionCardPost({
        deviceUUID: store.state.deviceUUID
      }, {
        successUrl: successUrl,
        cancelUrl: cancelUrl
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async createSession(priceId, successUrl, cancelUrl, freeTrial) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.stripeSessionPost({
        deviceUUID: store.state.deviceUUID
      }, {
        priceId: priceId,
        successUrl: successUrl,
        cancelUrl: cancelUrl,
        freeTrial: freeTrial
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async changeStripeSubscription(priceId) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.stripeSubscriptionPut({
        deviceUUID: store.state.deviceUUID
      }, {
        priceId: priceId
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async cancelStripeSubscription(reasons) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.stripeSubscriptionDelete({
        deviceUUID: store.state.deviceUUID
      }, reasons)
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }
}

export default (new ChargeApi())